$(".slider").slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 800,
  centerMode: true,
  centerPadding: "3%",
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "ease",
  useTransform: false,
  dotsClass: "custom-dots",
  responsive: [
    {
      breakpoint: 575,
      settings: {
        centerPadding: "2%",
      },
    },
  ],
});
